export default class wt {
  constructor(t, s, i, h, l) {
    (this.triggerId = t),
      (this.Er = s),
      (this.Tr = i),
      (this.Gr = h),
      (this.Nr = l),
      (this.triggerId = t),
      (this.Er = s),
      (this.Tr = i),
      (this.Gr = h),
      (this.Nr = l);
  }
  static fromJson(t, s, i, h, l) {
    return null == t || null == t.trigger_id
      ? null
      : new wt(t.trigger_id, s, i, h, l);
  }
}
