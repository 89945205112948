import InAppMessage from "./in-app-message.js";
import { DOMUtils as de } from "../../util/dom-utils.js";
export default class SlideUpMessage extends InAppMessage {
  constructor(
    e,
    t,
    s,
    o,
    i,
    r,
    n,
    d,
    a,
    u,
    p,
    m,
    c,
    l,
    v,
    x,
    f,
    h,
    g,
    I,
    M,
    b,
  ) {
    (x = x || InAppMessage.th.Dh),
      (v = v || InAppMessage.th.Mh),
      super(
        e,
        (t = t || InAppMessage.TextAlignment.START),
        s,
        o,
        i,
        r,
        n,
        d,
        a,
        u,
        p,
        m,
        void 0,
        c,
        l,
        v,
        x,
        f,
        h,
        g,
        void 0,
        void 0,
        void 0,
        void 0,
        void 0,
        void 0,
        void 0,
        I,
        M,
        b,
      ),
      (this.De = InAppMessage.TextAlignment.START);
  }
  ze() {
    return !1;
  }
  Je() {
    const e = document.createElement("span");
    return e.appendChild(document.createTextNode(this.message || "")), e;
  }
  Oh(e) {
    const t = e.getElementsByClassName("ab-in-app-message")[0];
    de.po(t, !0, !0) ||
      (this.slideFrom === InAppMessage.SlideFrom.TOP
        ? (t.style.top = "0px")
        : (t.style.bottom = "0px")),
      super.Oh(e);
  }
  Y() {
    return super.Y(SlideUpMessage.it);
  }
  static Xr(e) {
    return new SlideUpMessage(
      e[InAppMessage.tt.Vr],
      e[InAppMessage.tt.Wr],
      e[InAppMessage.tt.Rh],
      e[InAppMessage.tt.bt],
      e[InAppMessage.tt.Yr],
      e[InAppMessage.tt.Zr],
      e[InAppMessage.tt.URI],
      e[InAppMessage.tt.ea],
      e[InAppMessage.tt.ra],
      e[InAppMessage.tt.sa],
      e[InAppMessage.tt.ta],
      e[InAppMessage.tt.ot],
      e[InAppMessage.tt.aa],
      e[InAppMessage.tt.oa],
      e[InAppMessage.tt.pa],
      e[InAppMessage.tt.ma],
      e[InAppMessage.tt.na],
      e[InAppMessage.tt.ua],
      e[InAppMessage.tt.ca],
      e[InAppMessage.tt.ha],
      e[InAppMessage.tt.CSS],
      e[InAppMessage.tt.va],
    );
  }
}
SlideUpMessage.it = InAppMessage.qe.Kr;
