import C from "./util/net.js";
import ue from "./models/braze-event.js";
import s from "./common/event-logger.js";
import { randomInclusive as U } from "./util/math.js";
import t from "./models/request-result.js";
import {
  logger as r,
  IndexedDBAdapter as A,
  Guid as p,
  EventTypes as i,
} from "../shared-lib/index.js";
import { STORAGE_KEYS as o } from "./managers/storage-manager.js";
import T from "./managers/subscription-manager.js";
import vt from "./Push/utils/push-utils.js";
import D from "./util/request-header-utils.js";
export default class Lt {
  constructor(t, i, s, e, h, o, n, r, u, l) {
    (this.rn = t),
      (this.baseUrl = i),
      (this.ri = s),
      (this.on = e),
      (this.wt = h),
      (this.yt = o),
      (this.u = n),
      (this.wu = r),
      (this.zo = u),
      (this.qt = l),
      (this.rn = t),
      (this.baseUrl = i),
      (this.yu = 0),
      (this.fE = n.dE() || 0),
      (this.ju = null),
      (this.ri = s),
      (this.on = e),
      (this.wt = h),
      (this.yt = o),
      (this.u = n),
      (this.zo = u),
      (this.qt = l),
      (this.wu = r),
      (this.Su = new T()),
      (this.$u = null),
      (this.Cu = 50),
      (this.Fu = !1);
  }
  Lu(t, i) {
    return !t && !i && this.zo.Gh() >= this.Cu;
  }
  Mu(t) {
    let s = this.ri.Gl();
    if (t.length > 0) {
      const e = this.wt.getUserId();
      for (const h of t) {
        const t = (!h.userId && !e) || h.userId === e;
        h.type === i.ql && t && (s = !0);
      }
    }
    return s;
  }
  Pu(t = !1, i = !1, s = !0, e, h, n, u = !1, l = !1) {
    s && this.xu();
    const a = this.u.AE(),
      c = this.u.OE();
    let d = !1;
    const m = (t, i, r = -1) => {
        let u = !1;
        const l = new Date().valueOf();
        D._s(this.u, D.Ps.$a, l),
          -1 !== r && i.push(["X-Braze-Req-Tokens-Remaining", r.toString()]),
          C.Gs({
            url: this.baseUrl + "/data/",
            data: t,
            headers: i,
            L: (s) => {
              null != t.respond_with &&
                t.respond_with.triggers &&
                (this.yu = Math.max(this.yu - 1, 0)),
                this.qt.Ks(t, s, i)
                  ? (this.zo.Os(),
                    this.yt.al(s),
                    (null != t.respond_with &&
                      t.respond_with.user_id != this.wt.getUserId()) ||
                      (null != t.device && this.u.k(o.C.Ca, t.device),
                      null != t.sdk_metadata &&
                        (this.u.k(o.C.Yh, t.sdk_metadata),
                        this.u.k(o.C.Jh, this.ri.Si())),
                      this.wu(s),
                      D.Qs(this.u, D.Ps.$a, 1),
                      "function" == typeof e && e()))
                  : s.auth_error && (u = !0);
            },
            error: () => {
              null != t.respond_with &&
                t.respond_with.triggers &&
                (this.yu = Math.max(this.yu - 1, 0)),
                this.qt.Qh(t.events, t.attributes),
                "function" == typeof h && h();
            },
            Ws: (t) => {
              if (("function" == typeof n && n(t), s && !d)) {
                if (t && !u) this.Iu();
                else {
                  D.Ys(this.u, D.Ps.$a);
                  let t = this.ju;
                  (null == t || t < 1e3 * this.fE) && (t = 1e3 * this.fE),
                    this.Iu(Math.min(3e5, U(1e3 * this.fE, 3 * t)));
                }
                d = !0;
              }
            },
          });
      },
      f = this.Mu(a),
      g = i || f;
    if (this.Lu(u, f))
      return void r.info(
        "Declining to flush data due to 50 consecutive authentication failures",
      );
    if (s && !this.qt.Na(a, c, t, g))
      return this.Iu(), void ("function" == typeof n && n(!0));
    const v = this.qt.Xa(t, g, a, c, l);
    g && this.yu++;
    let p = !1;
    if (v)
      for (const t of v)
        this.qt.Xs(
          t.requestData,
          (i) => m(t.requestData, t.headers, i),
          D.Ps.$a,
          h,
        ),
          (p = !0);
    this.zo.Fh() && s && !p
      ? this.Iu()
      : f && (r.info("Invoking new session subscriptions"), this.Su.Dt());
  }
  Nu() {
    return this.yu > 0;
  }
  Iu(t = 1e3 * this.fE) {
    this.Fu ||
      (this.xu(),
      (this.$u = window.setTimeout(() => {
        if (document.hidden) {
          const t = "visibilitychange",
            i = () => {
              document.hidden ||
                (document.removeEventListener(t, i, !1), this.Pu());
            };
          document.addEventListener(t, i, !1);
        } else this.Pu();
      }, t)),
      (this.ju = t));
  }
  xu() {
    null != this.$u && (clearTimeout(this.$u), (this.$u = null));
  }
  initialize() {
    (this.Fu = !1), this.Iu();
  }
  destroy() {
    this.Su.removeAllSubscriptions(),
      this.zo.yh(),
      this.xu(),
      (this.Fu = !0),
      this.Pu(void 0, void 0, !1, void 0, void 0, void 0, void 0, !0),
      (this.$u = null);
  }
  mr(t) {
    return this.Su.It(t);
  }
  openSession() {
    const t = this.ri.Si() !== this.ri.wo();
    t && (this.u.cE(o.iu.Uo), this.u.cE(o.iu.su)),
      this.Pu(void 0, !1, void 0, () => {
        this.u.ti(o.C.vi);
      }),
      this.Mn(),
      t &&
        import("./Push/push-manager-factory.js").then((t) => {
          if (this.Fu) return;
          const i = t.default.m();
          if (
            null != i &&
            (vt.isPushPermissionGranted() || vt.isPushBlocked())
          ) {
            const t = () => {
                i.mn()
                  ? r.info(
                      "Push token maintenance is disabled, not refreshing token for backend.",
                    )
                  : i.subscribe();
              },
              s = (i, s) => {
                s && t();
              },
              e = () => {
                const i = this.u.j(o.C.In);
                (null == i || i) && t();
              },
              h = A.Yt.Qt;
            new A(h, r).jr(h.ss.cu, s, e);
          }
        });
  }
  Ou() {
    this.u.ti(o.C.gi), this.u.ti(o.C.ws), this.u.ti(o.C.Cr);
  }
  changeUser(t, i, s) {
    const e = this.wt.getUserId();
    if (e !== t) {
      this.ri.yl(),
        this.Ou(),
        null != e && this.Pu(void 0, !1, void 0, void 0, void 0),
        this.wt.ou(t),
        s ? this.zo.setSdkAuthenticationSignature(s) : this.zo.xh();
      for (let t = 0; t < i.length; t++) i[t].changeUser(null == e);
      null != e && this.u.ti(o.C.J),
        this.u.ti(o.C.Ca),
        this.u.ti(o.C.nE),
        this.u.ti(o.C._E),
        this.openSession(),
        r.info('Changed user to "' + t + '".');
    } else {
      let i = "Doing nothing.";
      s &&
        this.zo.kh() !== s &&
        (this.zo.setSdkAuthenticationSignature(s),
        (i = "Updated SDK authentication signature")),
        r.info(`Current user is already ${t}. ${i}`);
    }
  }
  requestImmediateDataFlush(t) {
    this.xu(), this.ri.wo();
    this.Pu(
      void 0,
      void 0,
      void 0,
      void 0,
      () => {
        r.error("Failed to flush data, request will be retried automatically.");
      },
      t,
      !0,
    );
  }
  requestFeedRefresh() {
    this.ri.wo(), this.Pu(!0);
  }
  $r(t, i) {
    this.ri.wo(),
      r.info("Requesting explicit trigger refresh."),
      this.Pu(void 0, !0, void 0, t, i);
  }
  Cn(t, e) {
    const h = i.zu,
      n = { a: t, l: e },
      u = s.q(h, n);
    return (
      u && (r.info(`Logged alias ${t} with label ${e}`), this.u.k(o.C._E, n)), u
    );
  }
  Fn(i, e, h) {
    if (this.yt.hu(e))
      return (
        r.info(`Custom Attribute "${e}" is blocklisted, ignoring.`), new t()
      );
    const o = { key: e, value: h },
      n = s.q(i, o);
    if (n) {
      const t = "object" == typeof h ? JSON.stringify(h, null, 2) : h;
      r.info(`Logged custom attribute: ${e} with value: ${t}`);
    }
    return n;
  }
  setLastKnownLocation(t, e, h, o, n, u) {
    const l = { latitude: e, longitude: h };
    null != o && (l.altitude = o),
      null != n && (l.ll_accuracy = n),
      null != u && (l.alt_accuracy = u);
    const a = s.q(i.Uu, l, t || void 0);
    return (
      a &&
        r.info(`Set user last known location as ${JSON.stringify(l, null, 2)}`),
      a
    );
  }
  kr(t, s) {
    const e = this.ri.wo();
    return new ue(this.wt.getUserId(), i._u, t, e, { cid: s });
  }
  Bu(t, i) {
    return new A(t, i);
  }
  Mn() {
    const t = A.Yt.Qt;
    this.Bu(t, r).setItem(t.ss.$a, 1, {
      baseUrl: this.baseUrl,
      data: { api_key: this.rn, device_id: this.on.ce().id },
      userId: this.wt.getUserId(),
      sdkAuthEnabled: this.zo.Fh(),
    });
  }
  yr(t) {
    for (const i of t)
      if (i.api_key === this.rn) this.qt.Qh(i.events, i.attributes);
      else {
        const t = A.Yt.Qt;
        new A(t, r).setItem(t.ss.wr, p.W(), i);
      }
  }
  Gn(e, h, o) {
    if (this.yt.hu(e))
      return (
        r.info(`Custom Attribute "${e}" is blocklisted, ignoring.`), new t()
      );
    let n, u;
    return (
      null === h && null === o
        ? ((n = i.Eu), (u = { key: e }))
        : ((n = i.Ju), (u = { key: e, latitude: h, longitude: o })),
      s.q(n, u)
    );
  }
  Hn(t, e) {
    const h = { group_id: t, status: e };
    return s.q(i.Ku, h);
  }
}
