import X from "../util/browser-detector.js";
import zt from "../models/device.js";
import DeviceProperties from "../Core/device-properties.js";
import _t from "../models/identifier.js";
import { logger as r, Guid as p } from "../../shared-lib/index.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import { values as Pt } from "../util/code-utils.js";
import { getErrorMessage as si } from "../util/error-utils.js";
export default class Ot {
  constructor(t, e) {
    (this.u = t),
      (this.ka = e),
      (this.u = t),
      null == e && (e = Pt(DeviceProperties)),
      (this.ka = e);
  }
  ce(t = !0) {
    let e = this.u.tu(o.iu.Uo);
    null == e && ((e = new _t(p.W())), t && this.u.uu(o.iu.Uo, e));
    const r = new zt(e.eu);
    for (let t = 0; t < this.ka.length; t++) {
      switch (this.ka[t]) {
        case DeviceProperties.BROWSER:
          r.browser = X.browser;
          break;
        case DeviceProperties.BROWSER_VERSION:
          r.Ia = X.version;
          break;
        case DeviceProperties.OS:
          r.os = this.Ta();
          break;
        case DeviceProperties.RESOLUTION:
          r.Da = screen.width + "x" + screen.height;
          break;
        case DeviceProperties.LANGUAGE:
          r.language = X.language;
          break;
        case DeviceProperties.TIME_ZONE:
          r.timeZone = this.Sa(new Date());
          break;
        case DeviceProperties.USER_AGENT:
          r.userAgent = X.userAgent;
      }
    }
    return r;
  }
  Ta() {
    if (X.Oa()) return X.Oa();
    const t = this.u.j(o.C.Ca);
    return t && t.os_version ? t.os_version : X.Ta();
  }
  Sa(t) {
    let e = !1;
    if ("undefined" != typeof Intl && "function" == typeof Intl.DateTimeFormat)
      try {
        if ("function" == typeof Intl.DateTimeFormat().resolvedOptions) {
          const t = Intl.DateTimeFormat().resolvedOptions().timeZone;
          if (null != t && "" !== t) return t;
        }
      } catch (t) {
        r.info(
          "Intl.DateTimeFormat threw an error, cannot detect user's time zone:" +
            si(t),
        ),
          (e = !0);
      }
    if (e) return "";
    const s = t.getTimezoneOffset();
    return this.Fa(s);
  }
  Fa(t) {
    const e = Math.trunc(t / 60),
      r = Math.trunc(t % 60);
    let s = "GMT";
    return (
      0 !== t &&
        ((s += t < 0 ? "+" : "-"),
        (s +=
          ("00" + Math.abs(e)).slice(-2) +
          ":" +
          ("00" + Math.abs(r)).slice(-2))),
      s
    );
  }
}
