const i = {
    CustomEvent: "ce",
    Pr: "p",
    _u: "pc",
    vc: "ca",
    Ea: "i",
    qs: "ie",
    M: "cci",
    R: "ccic",
    I: "ccc",
    F: "ccd",
    ql: "ss",
    xl: "se",
    Oi: "si",
    Ei: "sc",
    Gi: "sbc",
    Cc: "sfe",
    mo: "iec",
    Uu: "lr",
    zu: "uae",
    O: "ci",
    $: "cc",
    Ju: "lcaa",
    Eu: "lcar",
    On: "inc",
    Ln: "add",
    Rn: "rem",
    $n: "set",
    Bn: "ncam",
    Ku: "sgu",
    Fr: "ffi",
  },
  or = { Ar: "feed_displayed", Ec: "content_cards_displayed" };
export { i as EventTypes, or as InternalEventTypes };
