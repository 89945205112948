import e from "../managers/braze-instance.js";
import { getAlias as Gt } from "../managers/utils.js";
import { isObject as Nt } from "../util/code-utils.js";
import {
  convertMsToSeconds as h,
  timestampOrNow as Jt,
} from "../util/date-utils.js";
export default class ue {
  constructor(t, s, i, r, e) {
    (this.userId = t),
      (this.type = s),
      (this.time = i),
      (this.sessionId = r),
      (this.data = e),
      (this.userId = t),
      (this.type = s),
      (this.time = Jt(i)),
      (this.sessionId = r),
      (this.data = e);
  }
  Ir() {
    var t;
    const s = {
      name: this.type,
      time: h(this.time),
      data: this.data || {},
      session_id: this.sessionId,
    };
    null != this.userId && (s.user_id = this.userId);
    const i = (null === (t = e.Sr()) || void 0 === t ? void 0 : t.Fh()) || !1;
    if (!s.user_id && !i) {
      const t = Gt(e.l());
      t && (s.alias = t);
    }
    return s;
  }
  Y() {
    return {
      u: this.userId,
      t: this.type,
      ts: this.time,
      s: this.sessionId,
      d: this.data,
    };
  }
  static fromJson(t) {
    return new ue(t.user_id, t.name, t.time, t.session_id, t.data);
  }
  static RE(t) {
    return null != t && Nt(t) && null != t.t && "" !== t.t;
  }
  static qn(t) {
    return new ue(t.u, t.t, t.ts, t.s, t.d);
  }
}
