export default {
  Eo: "allowCrawlerActivity",
  _o: "baseUrl",
  Io: "noCookies",
  Ao: "devicePropertyAllowlist",
  _a: "disablePushTokenMaintenance",
  So: "enableLogging",
  No: "enableSdkAuthentication",
  Ma: "manageServiceWorkerExternally",
  Oo: "minimumIntervalBetweenTriggerActionsInSeconds",
  Po: "sessionTimeoutInSeconds",
  Ro: "appVersion",
  Lo: "appVersionNumber",
  wa: "serviceWorkerLocation",
  ya: "safariWebsitePushId",
  zn: "localization",
  bo: "contentSecurityNonce",
  Do: "allowUserSuppliedJavascript",
  jo: "inAppMessageZIndex",
  ho: "openInAppMessagesInNewTab",
  en: "openNewsFeedCardsInNewTab",
  Lh: "requireExplicitInAppMessageDismissal",
  Mo: "doNotLoadFontAwesome",
  Uo: "deviceId",
  Bo: "sdkFlavor",
  tn: "openCardsInNewTab",
};
