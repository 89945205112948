import { buttonsFromSerializedInAppMessage as pe } from "../in-app-message-factory.js";
import InAppMessage from "./in-app-message.js";
export default class ModalMessage extends InAppMessage {
  constructor(
    e,
    r,
    s,
    t,
    i,
    o,
    a,
    p,
    m,
    n,
    u,
    c,
    d,
    f,
    l,
    g,
    j,
    v,
    x,
    z,
    h,
    w,
    y,
    S,
    b,
    k,
    q,
    A,
  ) {
    super(
      e,
      r,
      void 0,
      s,
      t,
      i,
      o,
      a,
      (p = p || InAppMessage.DismissType.MANUAL),
      m,
      n,
      u,
      c,
      d,
      f,
      l,
      g,
      j,
      v,
      x,
      z,
      h,
      w,
      y,
      S,
      (b = b || InAppMessage.CropType.FIT_CENTER),
      void 0,
      k,
      q,
      A,
    ),
      (this.De = InAppMessage.TextAlignment.CENTER);
  }
  Y() {
    return super.Y(ModalMessage.it);
  }
  static Xr(e) {
    return new ModalMessage(
      e[InAppMessage.tt.Vr],
      e[InAppMessage.tt.Wr],
      e[InAppMessage.tt.bt],
      e[InAppMessage.tt.Yr],
      e[InAppMessage.tt.Zr],
      e[InAppMessage.tt.URI],
      e[InAppMessage.tt.ea],
      e[InAppMessage.tt.ra],
      e[InAppMessage.tt.sa],
      e[InAppMessage.tt.ta],
      e[InAppMessage.tt.ot],
      e[InAppMessage.tt.ia],
      e[InAppMessage.tt.aa],
      e[InAppMessage.tt.oa],
      e[InAppMessage.tt.pa],
      e[InAppMessage.tt.ma],
      e[InAppMessage.tt.na],
      e[InAppMessage.tt.ua],
      e[InAppMessage.tt.ca],
      e[InAppMessage.tt.fa],
      e[InAppMessage.tt.da],
      e[InAppMessage.tt.la],
      e[InAppMessage.tt.ga],
      pe(e[InAppMessage.tt.ja]),
      e[InAppMessage.tt.xa],
      e[InAppMessage.tt.ha],
      e[InAppMessage.tt.CSS],
      e[InAppMessage.tt.va],
    );
  }
}
ModalMessage.it = InAppMessage.qe.Jr;
