import lr from "./filter.js";
import { isArray as j } from "../../util/code-utils.js";
export default class ts {
  constructor(t) {
    (this.filters = t), (this.filters = t);
  }
  Jl(t) {
    let r = !0;
    for (let e = 0; e < this.filters.length; e++) {
      const o = this.filters[e];
      let s = !1;
      for (let r = 0; r < o.length; r++)
        if (o[r].Jl(t)) {
          s = !0;
          break;
        }
      if (!s) {
        r = !1;
        break;
      }
    }
    return r;
  }
  static fromJson(t) {
    if (null == t || !j(t)) return null;
    const r = [];
    for (let e = 0; e < t.length; e++) {
      const o = [],
        s = t[e];
      for (let t = 0; t < s.length; t++) o.push(lr.fromJson(s[t]));
      r.push(o);
    }
    return new ts(r);
  }
  Y() {
    const t = [];
    for (let r = 0; r < this.filters.length; r++) {
      const e = this.filters[r],
        o = [];
      for (let t = 0; t < e.length; t++) o.push(e[t].Y());
      t.push(o);
    }
    return t;
  }
  static qn(t) {
    const r = [];
    for (let e = 0; e < t.length; e++) {
      const o = [],
        s = t[e];
      for (let t = 0; t < s.length; t++) o.push(lr.qn(s[t]));
      r.push(o);
    }
    return new ts(r);
  }
}
