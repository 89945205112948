import { logger as r } from "../../shared-lib/index.js";
import xt from "../common/translations.js";
export default class nr {
  constructor(t, l = !1) {
    if (
      ((this.language = t),
      null != t && (t = t.toLowerCase()),
      null != t && null == xt[t])
    ) {
      const l = t.indexOf("-");
      l > 0 && (t = t.substring(0, l));
    }
    if (null == xt[t]) {
      const n =
        "Braze does not yet have a localization for language " +
        t +
        ", defaulting to English. Please contact us if you are willing and able to help us translate our SDK into this language.";
      l ? r.error(n) : r.info(n), (t = "en");
    }
    this.language = t;
  }
  get(t) {
    return xt[this.language][t];
  }
}
