import InAppMessage from "./in-app-message.js";
export default class HtmlMessage extends InAppMessage {
  constructor(i, o, e, r, d, t, s, v, n, u, a, c) {
    super(
      i,
      void 0,
      void 0,
      o,
      e,
      void 0,
      void 0,
      void 0,
      (r = r || InAppMessage.DismissType.MANUAL),
      d,
      void 0,
      void 0,
      void 0,
      void 0,
      void 0,
      void 0,
      void 0,
      void 0,
      t,
      s,
      void 0,
      void 0,
      void 0,
      v,
      void 0,
      void 0,
      void 0,
      n,
      u,
      c,
    ),
      (this.messageFields = a),
      (this.messageFields = a);
  }
  do() {
    return !1;
  }
  p(i) {
    if (this.we === InAppMessage.qe.Hr) {
      if (this.vo) return !1;
      this.vo = !0;
    }
    return this.Et.Dt(i), !0;
  }
  Y() {
    const i = super.Y(HtmlMessage.it);
    return (i[InAppMessage.tt.uo] = this.messageFields), i;
  }
  static Xr(i) {
    return new HtmlMessage(
      i[InAppMessage.tt.Vr],
      i[InAppMessage.tt.bt],
      i[InAppMessage.tt.Yr],
      i[InAppMessage.tt.ra],
      i[InAppMessage.tt.sa],
      i[InAppMessage.tt.ua],
      i[InAppMessage.tt.ca],
      i[InAppMessage.tt.ga],
      i[InAppMessage.tt.ha],
      i[InAppMessage.tt.CSS],
      i[InAppMessage.tt.uo],
      i[InAppMessage.tt.va],
    );
  }
}
HtmlMessage.it = InAppMessage.qe.Hr;
