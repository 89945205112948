import {
  isArray as j,
  isDate as yt,
  isObject as Nt,
  keys as to,
} from "./code-utils.js";
import { getByteLength as O } from "./string-utils.js";
import { logger as r } from "../../shared-lib/index.js";
import { toValidBackendTimeString as $t } from "./date-utils.js";
import { BRAZE_ACTIONS as V } from "./braze-actions.js";
import { SET_CUSTOM_USER_ATTRIBUTE_STRING as Ft } from "../common/constants.js";
export const CUSTOM_DATA_REGEX = /^[^\x00-\x1F\x22]+$/;
export const CUSTOM_ATTRIBUTE_SPECIAL_CHARS_REGEX = /[$.]/;
export const CUSTOM_ATTRIBUTE_RESERVED_OPERATORS = [
  "$add",
  "$update",
  "$remove",
  "$identifier_key",
  "$identifier_value",
  "$new_object",
  "$time",
  "$google_ad_personalization",
  "$google_ad_user_data",
];
export const EMAIL_ADDRESS_REGEX = new RegExp(/^.+@.+\..+$/);
export const CUSTOM_PROPERTIES_MAX_SIZE_BYTES = 51200;
export const CUSTOM_PROPERTIES_MAX_SIZE_STRING = "50KB";
export const CUSTOM_ATTRIBUTES_MAX_SIZE_BYTES = 76800;
export const CUSTOM_ATTRIBUTES_MAX_SIZE_STRING = "75KB";
export const CUSTOM_ATTRIBUTE_MAX_DEPTH = 50;
export const BRAZE_ACTION_URI_REGEX = /^brazeActions:\/\/v\d+\//;
export function validateCustomString(t, e, n) {
  const o =
    null != t &&
    "string" == typeof t &&
    ("" === t || null != t.match(CUSTOM_DATA_REGEX));
  return o || r.error(`Cannot ${e} because ${n} "${t}" is invalid.`), o;
}
export function validateCustomAttributeKey(t) {
  return (
    null != t &&
      t.match(CUSTOM_ATTRIBUTE_SPECIAL_CHARS_REGEX) &&
      -1 === CUSTOM_ATTRIBUTE_RESERVED_OPERATORS.indexOf(t) &&
      r.warn("Custom attribute keys cannot contain '$' or '.'"),
    validateCustomString(t, "set custom user attribute", "the given key")
  );
}
export function validatePropertyType(t) {
  const e = typeof t;
  return (
    null == t || "number" === e || "boolean" === e || yt(t) || "string" === e
  );
}
export function _validateNestedProperties(t, e, n) {
  const o = -1 !== n;
  if (o && n > 50)
    return r.error("Nested attributes cannot be more than 50 levels deep."), !1;
  const i = o ? n + 1 : -1;
  if (j(t) && j(e)) {
    for (let r = 0; r < t.length && r < e.length; r++)
      if (
        (yt(t[r]) && (e[r] = $t(t[r])),
        !_validateNestedProperties(t[r], e[r], i))
      )
        return !1;
  } else {
    if (!Nt(t)) return validatePropertyType(t);
    for (const r of to(t)) {
      const n = t[r];
      if (o && !validateCustomAttributeKey(r)) return !1;
      if (yt(n)) {
        e[r] = $t(n);
      }
      if (!_validateNestedProperties(n, e[r], i)) return !1;
    }
  }
  return !0;
}
export function _validateEventPropertyValue(t, e, n, o, i) {
  let a;
  return (
    (a =
      Nt(t) || j(t)
        ? _validateNestedProperties(t, e, i ? 1 : -1)
        : validatePropertyType(t)),
    a || r.error(`Cannot ${n} because ${o} "${t}" is invalid.`),
    a
  );
}
export function validateStandardString(t, e, n, o = !1) {
  const i = "string" == typeof t || (null === t && o);
  return i || r.error(`Cannot ${e} because ${n} "${t}" is invalid.`), i;
}
export function validateCustomProperties(t, e, n, o, i) {
  if ((null == t && (t = {}), "object" != typeof t || j(t)))
    return (
      r.error(`${e} requires that ${n} be an object. Ignoring ${i}.`),
      [!1, null]
    );
  let a, s;
  e === Ft ? ((a = 76800), (s = "75KB")) : ((a = 51200), (s = "50KB"));
  const u = JSON.stringify(t);
  if (O(u) > a)
    return (
      r.error(
        `Could not ${o} because ${n} was greater than the max size of ${s}.`,
      ),
      [!1, null]
    );
  let l;
  try {
    l = JSON.parse(u);
  } catch (t) {
    return (
      r.error(`Could not ${o} because ${n} did not contain valid JSON.`),
      [!1, null]
    );
  }
  for (const r in t) {
    if (e === Ft && !validateCustomAttributeKey(r)) return [!1, null];
    if (!validateCustomString(r, o, `the ${i} property name`))
      return [!1, null];
    const n = t[r];
    if (e !== Ft && null == n) {
      delete t[r], delete l[r];
      continue;
    }
    yt(n) && (l[r] = $t(n));
    if (
      !_validateEventPropertyValue(
        n,
        l[r],
        o,
        `the ${i} property "${r}"`,
        e === Ft,
      )
    )
      return [!1, null];
  }
  return [!0, l];
}
export function validateCustomAttributeArrayType(t, e) {
  let n = !1,
    o = !1;
  const i = () => {
    r.error(
      "Custom attribute arrays must be either string arrays or object arrays.",
    );
  };
  for (const r of e)
    if ("string" == typeof r) {
      if (o) return i(), [!1, !1];
      if (
        !validateCustomString(
          r,
          `set custom user attribute "${t}"`,
          "the element in the given array",
        )
      )
        return [!1, !1];
      n = !0;
    } else {
      if (!Nt(r)) return i(), [!1, !1];
      if (n) return i(), [!1, !1];
      if (
        !validateCustomProperties(
          r,
          Ft,
          "attribute value",
          `set custom user attribute "${t}"`,
          "custom user attribute",
        )
      )
        return [!1, !1];
      o = !0;
    }
  return [n, o];
}
export function isValidEmail(t) {
  return (
    "string" == typeof t && null != t.toLowerCase().match(EMAIL_ADDRESS_REGEX)
  );
}
export function isValidBrazeActionJson(t) {
  if (!(V.properties.type in t)) return !1;
  switch (t[V.properties.type]) {
    case V.types.ro:
      if (V.properties.oo in t) return !0;
      break;
    case V.types.logCustomEvent:
    case V.types.setEmailNotificationSubscriptionType:
    case V.types.setPushNotificationSubscriptionType:
    case V.types.setCustomUserAttribute:
    case V.types.addToSubscriptionGroup:
    case V.types.removeFromSubscriptionGroup:
    case V.types.addToCustomAttributeArray:
    case V.types.removeFromCustomAttributeArray:
    case V.types.no:
    case V.types.ao:
      if (V.properties.eo in t) return !0;
      break;
    case V.types.requestPushPermission:
      return !0;
    default:
      return !1;
  }
  return !1;
}
export function isValidBrazeActionType(t) {
  let e = !1;
  return (
    Object.keys(V.types).forEach((r) => {
      V.types[r] !== t.toString() || (e = !0);
    }),
    e
  );
}
