import qt from "../models/backend-errors.js";
import ue from "../models/braze-event.js";
import {
  convertMsToSeconds as h,
  convertSecondsToMs as Xt,
} from "../util/date-utils.js";
import s from "../common/event-logger.js";
import { isArray as j, isEqual as ii } from "../util/code-utils.js";
import { logger as r, EventTypes as i } from "../../shared-lib/index.js";
import { STORAGE_KEYS as o } from "./storage-manager.js";
import D from "../util/request-header-utils.js";
import { LAST_REQUEST_TO_ENDPOINT_MS_AGO_DEFAULT as Kt } from "../common/constants.js";
import { getAlias as Gt } from "./utils.js";
export default class Rt {
  constructor(t, e, i, s, r, n, o, h, a, u, l, c) {
    (this.on = t),
      (this.u = e),
      (this.zo = i),
      (this.wt = s),
      (this.ri = r),
      (this.yt = n),
      (this.rn = o),
      (this.Go = h),
      (this.Wo = a),
      (this.Vo = u),
      (this.appVersion = l),
      (this.Xh = c),
      (this.$h = (t) => (null == t ? "" : `${t} `)),
      (this.on = t),
      (this.u = e),
      (this.zo = i),
      (this.wt = s),
      (this.ri = r),
      (this.yt = n),
      (this.rn = o),
      (this.Go = h),
      (this.Wo = a),
      (this.Vo = u),
      (this.appVersion = l),
      (this.Xh = c),
      (this.Hh = ["npm"]);
  }
  Js(t, e = !1, i = !1) {
    const s = this.on.ce(!i),
      r = s.Ir(),
      n = this.u.j(o.C.Ca);
    ii(n, r) || (t.device = r),
      (t.api_key = this.rn),
      (t.time = h(new Date().valueOf(), !0));
    const a = this.u.j(o.C.Yh) || [],
      u = this.u.j(o.C.Jh) || "";
    if (
      (this.Hh.length > 0 &&
        (!ii(a, this.Hh) || u !== this.ri.Si()) &&
        (t.sdk_metadata = this.Hh),
      (t.sdk_version = this.Wo),
      this.Vo && (t.sdk_flavor = this.Vo),
      (t.app_version = this.appVersion),
      (t.app_version_code = this.Xh),
      (t.device_id = s.id),
      e)
    ) {
      const e = this.wt.getUserId();
      null != e && (t.user_id = e);
    }
    if (!t.user_id && !this.zo.Fh()) {
      const e = Gt(this.u);
      e && (t.alias = e);
    }
    return t;
  }
  Ks(t, e, n) {
    const o = e.auth_error,
      h = e.error;
    if (!o && !h) return !0;
    if (o) {
      let e;
      this.zo.Bh();
      const i = { errorCode: o.error_code };
      for (const t of n)
        j(t) && "X-Braze-Auth-Signature" === t[0] && (i.signature = t[1]);
      t.respond_with && t.respond_with.user_id
        ? (i.userId = t.respond_with.user_id)
        : t.user_id && (i.userId = t.user_id);
      const s = o.reason;
      return (
        s
          ? ((i.reason = s), (e = `due to ${s}`))
          : (e = `with error code ${o.error_code}.`),
        this.zo.Fh() ||
          (e +=
            ' Please use the "enableSdkAuthentication" initialization option to enable authentication.'),
        r.error(`SDK Authentication failed ${e}`),
        this.Qh(t.events || [], t.attributes || []),
        this.zo.qh(i),
        !1
      );
    }
    if (h) {
      let n,
        o = h;
      switch (o) {
        case qt.Zh:
          return (
            (n = "Received successful response with empty body."),
            s.q(i.qs, { e: n }),
            r.info(n),
            !1
          );
        case qt.Ra:
          return (
            (n = "Received successful response with invalid JSON"),
            s.q(i.qs, { e: n + ": " + e.response }),
            r.info(n),
            !1
          );
        case qt.qa:
          o = `The API key "${t.api_key}" is invalid for the baseUrl ${this.Go}`;
          break;
        case qt.Aa:
          o =
            "Sorry, we are not currently accepting your requests. If you think this is in error, please contact us.";
          break;
        case qt.Ba:
          o = "No device identifier. Please contact support@braze.com";
      }
      r.error("Backend error: " + o);
    }
    return !1;
  }
  Na(t, e, i, s) {
    return !!((t && 0 !== t.length) || (e && 0 !== e.length) || i || s);
  }
  Xa(t, e, i, s, r = !1) {
    const n = [],
      o = (t) => t || "",
      h = o(this.wt.getUserId());
    let a = this.Zi(t, e);
    const u = [],
      l = [];
    let c,
      d = null;
    if (i.length > 0) {
      const t = [];
      for (const e of i) {
        if (((c = e.Ir()), this.zo.Fh())) {
          if (h && !c.user_id) {
            d || (d = {}), d.events || (d.events = []), d.events.push(c);
            continue;
          }
          if (o(c.user_id) !== h) {
            l.push(c);
            continue;
          }
        }
        t.push(c);
      }
      t.length > 0 && (a.events = t);
    }
    if (s.length > 0) {
      const t = [];
      for (const e of s)
        e && (this.zo.Fh() && o(e.user_id) !== h ? u.push(e) : t.push(e));
      t.length > 0 && (a.attributes = t);
    }
    if ((this.Qh(l, u), (a = this.Js(a, !0, r)), d)) {
      d = this.Js(d, !1, r);
      const t = { requestData: d, headers: this.$s(d, D.Ps.$a) };
      n.push(t);
    }
    if (a && !this.Na(a.events, a.attributes, t, e)) return d ? n : null;
    const f = { requestData: a, headers: this.$s(a, D.Ps.$a) };
    return n.push(f), n;
  }
  Qh(t, e) {
    if (t) {
      const e = [];
      for (const i of t) {
        const t = ue.fromJson(i);
        (t.time = Xt(t.time)), e.push(t);
      }
      this.u.xo(e);
    }
    if (e) for (const t of e) this.u.La(t);
  }
  Vs(t, e) {
    let i = "HTTP error ";
    null != t && (i += t + " "), (i += e), r.error(i);
  }
  qr(t) {
    return s.q(i.Ea, { n: t });
  }
  Zi(t, e, i) {
    const s = {};
    t && (s.feed = !0), e && (s.triggers = !0);
    const r = null != i ? i : this.wt.getUserId();
    if ((r && (s.user_id = r), !s.user_id && !this.zo.Fh())) {
      const t = Gt(this.u);
      t && (s.alias = t);
    }
    return (s.config = { config_time: this.yt.ii() }), { respond_with: s };
  }
  Ka(t) {
    const e = new Date().valueOf();
    let i = Kt.toString();
    const s = D.Pa(this.u, t);
    if (-1 !== s) {
      i = (e - s).toString();
    }
    return i;
  }
  $s(t, e, i = !1) {
    const s = [["X-Braze-Api-Key", this.rn]],
      r = this.Ka(e);
    s.push(["X-Braze-Last-Req-Ms-Ago", r]);
    const n = D.Ua(this.u, e).toString();
    s.push(["X-Braze-Req-Attempt", n]);
    let h = !1;
    if (
      (null != t.respond_with &&
        t.respond_with.triggers &&
        (s.push(["X-Braze-TriggersRequest", "true"]), (h = !0)),
      null != t.respond_with &&
        t.respond_with.feed &&
        (s.push(["X-Braze-FeedRequest", "true"]), (h = !0)),
      e === D.Ps.Bs)
    ) {
      s.push(["X-Braze-ContentCardsRequest", "true"]);
      let t = this.u.j(o.C.As);
      (t && i) || ((t = 0), this.u.k(o.C.As, t)),
        s.push(["BRAZE-SYNC-RETRY-COUNT", t.toString()]),
        (h = !0);
    }
    if (
      (e === D.Ps.bi &&
        (s.push(["X-Braze-FeatureFlagsRequest", "true"]), (h = !0)),
      h && s.push(["X-Braze-DataRequest", "true"]),
      this.zo.Fh())
    ) {
      const t = this.zo.kh();
      null != t && s.push(["X-Braze-Auth-Signature", t]);
    }
    return s;
  }
  Ga(t, e, i, s) {
    window.setTimeout(() => {
      r.info(`Retrying rate limited ${this.$h(s)}SDK request.`),
        this.Xs(e, i, s);
    }, t);
  }
  Xs(t, e, i, s) {
    if (!this.Ha(i))
      return (
        r.info(`${this.$h(i)}SDK request being rate limited.`),
        void ("function" == typeof s && s())
      );
    const n = this.Wa();
    if (!n.Ya)
      return (
        r.info(
          `${this.$h(
            i,
          )}SDK request being rate limited. Request will be retried in ${Math.trunc(
            n.Ja / 1e3,
          )} seconds.`,
        ),
        void this.Ga(n.Ja, t, e, i)
      );
    this.u.k(o.C.Qa, new Date().valueOf());
    const h = t.device;
    h && h.os_version instanceof Promise
      ? h.os_version.then((i) => {
          (t.device.os_version = i), e(n.Va);
        })
      : e(n.Va);
  }
  Za(t) {
    var e;
    null === (e = this.u) || void 0 === e || e.k(o.C.au, t);
  }
  du(t, e) {
    let i = this.fu();
    null == i && (i = {}), (i[t] = e), this.u.k(o.C.vu, i);
  }
  Ru() {
    var t;
    return null === (t = this.u) || void 0 === t ? void 0 : t.j(o.C.au);
  }
  fu() {
    var t;
    return null === (t = this.u) || void 0 === t ? void 0 : t.j(o.C.vu);
  }
  gu(t, e, i, s, r = "") {
    let n;
    if (r) {
      const t = this.fu();
      n = null == t || isNaN(t[r]) ? e : t[r];
    } else (n = this.Ru()), (null == n || isNaN(n)) && (n = e);
    const o = (t - s) / 1e3;
    return (n = Math.min(n + o / i, e)), n;
  }
  pu(t, e) {
    return Math.max(0, (1 - t) * e * 1e3);
  }
  bu(t, e = "") {
    var i, s, r, n, h;
    const a = { Ya: !0, Va: -1, Ja: 0 };
    if ((null == t && (t = !0), !t && !e)) return a;
    let u,
      l,
      c = null;
    if (t) c = null === (i = this.u) || void 0 === i ? void 0 : i.j(o.C.Qa);
    else {
      const t = D.qu(this.u);
      if (null == t || null == t[e]) return a;
      c = t[e];
    }
    if (null == c || isNaN(c)) return a;
    if (
      (t
        ? ((u =
            (null === (s = this.yt) || void 0 === s ? void 0 : s.Au()) || -1),
          (l =
            (null === (r = this.yt) || void 0 === r ? void 0 : r.Du()) || -1))
        : ((u =
            (null === (n = this.yt) || void 0 === n ? void 0 : n.Tu(e)) || -1),
          (l =
            (null === (h = this.yt) || void 0 === h ? void 0 : h.ku(e)) || -1)),
      -1 === u || -1 === l)
    )
      return a;
    const d = new Date().valueOf();
    let f = this.gu(d, u, l, c, e);
    return f < 1
      ? ((a.Ya = !1), (a.Ja = this.pu(f, l)), a)
      : ((f = Math.trunc(f) - 1),
        (a.Va = f),
        t ? this.Za(f) : this.du(e, f),
        a);
  }
  Wa() {
    return this.bu(!0);
  }
  Ha(t) {
    const e = this.bu(!1, t);
    return !(e && !e.Ya);
  }
  Os() {
    this.zo.Os();
  }
  Hs() {
    return this.Go;
  }
  addSdkMetadata(t) {
    for (const e of t) -1 === this.Hh.indexOf(e) && this.Hh.push(e);
  }
}
