import ri from "./trigger-condition.js";
export default class ur {
  constructor(t) {
    (this.Zu = t), (this.Zu = t);
  }
  Jl(t) {
    return null == this.Zu || ri.if(t[0], this.Zu);
  }
  static fromJson(t) {
    return new ur(t ? t.campaign_id : null);
  }
  Y() {
    return this.Zu;
  }
}
