import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import ue from "../models/braze-event.js";
import _t from "../models/identifier.js";
import { convertMsToSeconds as h } from "../util/date-utils.js";
import {
  logger as r,
  Guid as p,
  EventTypes as i,
} from "../../shared-lib/index.js";
export default class Mt {
  constructor(s, t, i, e) {
    (this.u = s),
      (this.wt = t),
      (this.yt = i),
      (this.wl = e),
      (this.u = s),
      (this.wt = t),
      (this.yt = i),
      (this.Sl = 1e3),
      (null == e || isNaN(e)) && (e = 1800),
      e < this.Sl / 1e3 &&
        (r.info(
          "Specified session timeout of " +
            e +
            "s is too small, using the minimum session timeout of " +
            this.Sl / 1e3 +
            "s instead.",
        ),
        (e = this.Sl / 1e3)),
      (this.wl = e);
  }
  jl(s, t) {
    return new ue(this.wt.getUserId(), i.xl, s, t.eu, { d: h(s - t.Dl) });
  }
  Si() {
    const s = this.u.tu(o.iu.El);
    return null == s ? null : s.eu;
  }
  Gl() {
    const s = new Date().valueOf(),
      t = this.yt.dl(),
      i = this.u.j(o.C.Nl);
    if (null != i && null == t) return !1;
    let e = !1;
    return (
      null == i ? (e = !0) : null != t && (e = s - i > 1e3 * t),
      e && this.u.k(o.C.Nl, s),
      e
    );
  }
  zl(s, t) {
    return null == t || null == t.Hl || (!(s - t.Dl < this.Sl) && t.Hl < s);
  }
  wo() {
    const s = new Date().valueOf(),
      t = s + 1e3 * this.wl,
      e = this.u.tu(o.iu.El);
    if (this.zl(s, e)) {
      let n = "Generating session start event with time " + s;
      if (null != e) {
        let s = e.Wl;
        s - e.Dl < this.Sl && (s = e.Dl + this.Sl),
          this.u.kl(this.jl(s, e)),
          (n += " (old session ended " + s + ")");
      }
      (n += ". Will expire " + t.valueOf()), r.info(n);
      const l = new _t(p.W(), t);
      this.u.kl(new ue(this.wt.getUserId(), i.ql, s, l.eu)),
        this.u.uu(o.iu.El, l);
      return null == this.u.j(o.C.Nl) && this.u.k(o.C.Nl, s), l.eu;
    }
    if (null != e) return (e.Wl = s), (e.Hl = t), this.u.uu(o.iu.El, e), e.eu;
  }
  yl() {
    const s = this.u.tu(o.iu.El);
    null != s &&
      (this.u.Al(o.iu.El), this.u.kl(this.jl(new Date().valueOf(), s)));
  }
}
