import { logger as r } from "../../shared-lib/index.js";
import e from "../managers/braze-instance.js";
import ue from "../models/braze-event.js";
import t from "../models/request-result.js";
const s = {
  q: (o, n, s) => {
    var i, l;
    const d = new t(),
      m = e.zr();
    if (!m)
      return (
        r.info(
          `Not logging event with type "${o}" because the current session ID could not be found.`,
        ),
        d
      );
    const u = m.wo();
    return (
      d.ge.push(
        new ue(
          s || (null === (i = e.er()) || void 0 === i ? void 0 : i.getUserId()),
          o,
          new Date().valueOf(),
          u,
          n,
        ),
      ),
      (d.L =
        (null === (l = e.l()) || void 0 === l ? void 0 : l.xo(d.ge)) || !1),
      d
    );
  },
};
export default s;
