import InAppMessage from "./models/in-app-message.js";
import ControlMessage from "./models/control-message.js";
import FullScreenMessage from "./models/full-screen-message.js";
import HtmlMessage from "./models/html-message.js";
import InAppMessageButton from "./models/in-app-message-button.js";
import ModalMessage from "./models/modal-message.js";
import SlideUpMessage from "./models/slide-up-message.js";
import { logger as r } from "../../shared-lib/index.js";
export function newInAppMessageFromJson(e) {
  if (!e) return null;
  if (e.is_control) return ControlMessage.fromJson(e);
  let o = e.type;
  null != o && (o = o.toUpperCase());
  const s = e.message,
    n = e.text_align_message,
    t = e.slide_from,
    m = e.extras,
    l = e.trigger_id,
    i = e.click_action,
    f = e.uri,
    p = e.open_target,
    u = e.message_close,
    a = e.duration,
    d = e.icon,
    g = e.image_url,
    c = e.image_style,
    j = e.icon_color,
    w = e.icon_bg_color,
    b = e.bg_color,
    h = e.text_color,
    v = e.close_btn_color,
    x = e.header,
    I = e.text_align_header,
    A = e.header_text_color,
    F = e.frame_color,
    M = [];
  let k = e.btns;
  null == k && (k = []);
  for (let e = 0; e < k.length; e++) M.push(InAppMessageButton.fromJson(k[e]));
  const y = e.crop_type,
    z = e.orientation,
    J = e.animate_in,
    S = e.animate_out;
  let q = e.html_id,
    B = e.css;
  (null != q && "" !== q && null != B && "" !== B) ||
    ((q = void 0), (B = void 0));
  const C = e.message_extras;
  let D;
  if (o === ModalMessage.it || o === InAppMessage.qe.Ve)
    D = new ModalMessage(
      s,
      n,
      m,
      l,
      i,
      f,
      p,
      u,
      a,
      d,
      g,
      c,
      j,
      w,
      b,
      h,
      v,
      J,
      S,
      x,
      I,
      A,
      F,
      M,
      y,
      q,
      B,
      C,
    );
  else if (o === FullScreenMessage.it)
    D = new FullScreenMessage(
      s,
      n,
      m,
      l,
      i,
      f,
      p,
      u,
      a,
      d,
      g,
      c,
      j,
      w,
      b,
      h,
      v,
      J,
      S,
      x,
      I,
      A,
      F,
      M,
      y,
      z,
      q,
      B,
      C,
    );
  else if (o === SlideUpMessage.it)
    D = new SlideUpMessage(
      s,
      n,
      t,
      m,
      l,
      i,
      f,
      p,
      u,
      a,
      d,
      g,
      j,
      w,
      b,
      h,
      v,
      J,
      S,
      q,
      B,
      C,
    );
  else {
    if (
      o !== HtmlMessage.it &&
      o !== InAppMessage.qe.$e &&
      o !== InAppMessage.qe.We
    )
      return void r.error("Ignoring message with unknown type " + o);
    {
      const o = e.message_fields;
      (D = new HtmlMessage(s, m, l, u, a, J, S, F, q, B, o, C)),
        (D.trusted = e.trusted || !1);
    }
  }
  return (D.we = o), D;
}
export function buttonsFromSerializedInAppMessage(e) {
  const o = [];
  for (const s of e)
    o.push(
      new InAppMessageButton(
        s.text,
        s.backgroundColor,
        s.textColor,
        s.borderColor,
        s.clickAction,
        s.uri,
        s.id,
      ),
    );
  return o;
}
