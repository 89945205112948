export const isIFrame = (o) => null !== o && "IFRAME" === o.tagName;
export function nodeScriptReplace(o) {
  var n;
  if ("SCRIPT" === o.tagName)
    null === (n = o.parentNode) ||
      void 0 === n ||
      n.replaceChild(
        (function (o) {
          const n = document.createElement("script");
          n.text = o.innerHTML;
          for (const e of o.attributes) n.setAttribute(e.name, e.value);
          return n;
        })(o),
        o,
      );
  else for (const n of o.childNodes) nodeScriptReplace(n);
  return o;
}
