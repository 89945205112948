import e from "../managers/braze-instance.js";
import ControlMessage from "./models/control-message.js";
import InAppMessage from "./models/in-app-message.js";
import se from "./in-app-message-manager-factory.js";
import { MUST_BE_IN_APP_MESSAGE_WARNING as sr } from "../common/constants.js";
import { logger as r, EventTypes as i } from "../../shared-lib/index.js";
export function logInAppMessageImpression(o) {
  if (!e.X()) return !1;
  if (!(o instanceof InAppMessage || o instanceof ControlMessage))
    return r.error(sr), !1;
  const s = o instanceof ControlMessage ? i.mo : i.Oi;
  return se.m().q(o, s).L;
}
