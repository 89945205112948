import ai from "./client-hints-parser.js";
import oi from "./user-agent-parser.js";
import { Browsers as gi, OperatingSystems as Y } from "./device-constants.js";
class ui {
  constructor() {
    const t = navigator.userAgentData ? ai : oi;
    (this.Sg = new t()),
      (this.userAgent = navigator.userAgent),
      (this.browser = this.Sg.ef()),
      (this.version = this.Sg.rf()),
      (this.OS = null),
      this.Ta().then((t) => (this.OS = t));
    const i = navigator;
    (this.language = (
      i.userLanguage ||
      i.language ||
      i.browserLanguage ||
      i.systemLanguage ||
      ""
    ).toLowerCase()),
      (this.Zo = ui.vg(this.userAgent));
  }
  IE() {
    return this.browser === gi.Bg;
  }
  Oa() {
    return this.OS || null;
  }
  Ta() {
    return this.OS
      ? Promise.resolve(this.OS)
      : this.Sg.Ta(ui.kg).then((t) => ((this.OS = t), t));
  }
  static vg(t) {
    t = t.toLowerCase();
    const i = [
      "googlebot",
      "bingbot",
      "slurp",
      "duckduckbot",
      "baiduspider",
      "yandex",
      "facebookexternalhit",
      "sogou",
      "ia_archiver",
      "https://github.com/prerender/prerender",
      "aolbuild",
      "bingpreview",
      "msnbot",
      "adsbot",
      "mediapartners-google",
      "teoma",
      "taiko",
    ];
    for (let n = 0; n < i.length; n++) if (-1 !== t.indexOf(i[n])) return !0;
    return !1;
  }
}
ui.kg = [
  { string: navigator.platform, ff: "Win", identity: Y.Og },
  { string: navigator.platform, ff: "Mac", identity: Y.Pg },
  { string: navigator.platform, ff: "BlackBerry", identity: "BlackBerry" },
  { string: navigator.platform, ff: "FreeBSD", identity: "FreeBSD" },
  { string: navigator.platform, ff: "OpenBSD", identity: "OpenBSD" },
  { string: navigator.platform, ff: "Nintendo", identity: "Nintendo" },
  { string: navigator.platform, ff: "SunOS", identity: "SunOS" },
  { string: navigator.platform, ff: "PlayStation", identity: "PlayStation" },
  { string: navigator.platform, ff: "X11", identity: "X11" },
  {
    string: navigator.userAgent,
    ff: ["iPhone", "iPad", "iPod"],
    identity: Y.io,
  },
  { string: navigator.platform, ff: "Pike v", identity: Y.io },
  { string: navigator.userAgent, ff: ["Web0S"], identity: "WebOS" },
  {
    string: navigator.platform,
    ff: ["Linux armv7l", "Android"],
    identity: Y.xg,
  },
  { string: navigator.userAgent, ff: ["Android"], identity: Y.xg },
  { string: navigator.platform, ff: "Linux", identity: "Linux" },
];
const X = new ui();
export { X as default, ui as BrowserDetector };
