import e, { OPTIONS as L } from "../../managers/braze-instance.js";
import ControlMessage from "../models/control-message.js";
import HtmlMessage from "../models/html-message.js";
import InAppMessage from "../models/in-app-message.js";
import SlideUpMessage from "../models/slide-up-message.js";
import me from "../display/get-animation-effect.js";
import ce from "../display/in-app-message-to-html.js";
import { logInAppMessageImpression } from "../log-in-app-message-impression.js";
import {
  ORIENTATION as fe,
  WindowUtils as eo,
} from "../../util/window-utils.js";
import { isURIJavascriptOrData as R } from "../../util/url-utils.js";
import { KeyCodes as lt } from "../../util/key-codes.js";
import { setupInAppMessageUI as ss } from "../../ui/js/index.js";
import { logger as r } from "../../../shared-lib/index.js";
import { toRgba as ie } from "../../util/color-utils.js";
import { BRAZE_MUST_BE_INITIALIZED_ERROR as _ } from "../../common/constants.js";
import { isIFrame as It } from "../utils/in-app-message-utils.js";
export function showInAppMessage(t, s, o) {
  if (!e.X()) return;
  if ((ss(), null == t)) return !1;
  if (t instanceof ControlMessage)
    return (
      r.info(
        "User received control for a multivariate test, logging to Braze servers.",
      ),
      logInAppMessageImpression(t),
      !0
    );
  if (!(t instanceof InAppMessage)) return !1;
  if (t.constructor === InAppMessage) return !1;
  t.Nh();
  const i = t instanceof HtmlMessage;
  if (i && !t.trusted && !e.tr())
    return (
      r.error(
        'HTML in-app messages are disabled. Use the "allowUserSuppliedJavascript" option for braze.initialize to enable these messages.',
      ),
      !1
    );
  if ((null == s && (s = document.body), t.ze())) {
    if (s.querySelectorAll(".ab-modal-interactions").length > 0)
      return (
        r.info(
          `Cannot show in-app message ${t.message} because another message is being shown.`,
        ),
        !1
      );
  }
  if (eo.fo()) {
    const e = eo.co();
    if (
      (e === fe.PORTRAIT &&
        t.orientation === InAppMessage.Orientation.LANDSCAPE) ||
      (e === fe.LANDSCAPE &&
        t.orientation === InAppMessage.Orientation.PORTRAIT)
    ) {
      const s = e === fe.PORTRAIT ? "portrait" : "landscape",
        o =
          t.orientation === InAppMessage.Orientation.PORTRAIT
            ? "portrait"
            : "landscape";
      return (
        r.info(
          `Not showing ${o} in-app message ${t.message} because the screen is currently ${s}`,
        ),
        !1
      );
    }
  }
  if (!e.tr()) {
    let e = !1;
    if (t.buttons && t.buttons.length > 0) {
      const s = t.buttons;
      for (let t = 0; t < s.length; t++)
        if (s[t].clickAction === InAppMessage.ClickAction.URI) {
          const o = s[t].uri;
          e = R(o);
        }
    } else t.clickAction === InAppMessage.ClickAction.URI && (e = R(t.uri));
    if (e)
      return (
        r.error(
          'Javascript click actions are disabled. Use the "allowUserSuppliedJavascript" option for braze.initialize to enable these actions.',
        ),
        !1
      );
  }
  const n = document.createElement("div");
  if (
    ((n.className = "ab-iam-root v3"),
    (n.className += me(t)),
    n.setAttribute("role", "complementary"),
    t.xe() && (n.id = t.htmlId),
    e.nn(L.jo) && (n.style.zIndex = (e.nn(L.jo) + 1).toString()),
    s.appendChild(n),
    t.Ce())
  ) {
    const s = document.createElement("style");
    (s.innerHTML = t.css),
      (s.id = t.ve()),
      null != e.nn(L.bo) && s.setAttribute("nonce", e.nn(L.bo)),
      document.getElementsByTagName("head")[0].appendChild(s);
  }
  const a = t instanceof SlideUpMessage,
    l = ce(
      t,
      () => {
        import("../../Feed/ui/show-feed.js").then((t) => {
          e.so() ? t.showFeed() : r.error(_);
        });
      },
      (s) => {
        if (t.ze() && t.do()) {
          const o = document.createElement("div");
          if (
            ((o.className = "ab-page-blocker"),
            t.Ce() || (o.style.backgroundColor = ie(t.frameColor)),
            e.nn(L.jo) && (o.style.zIndex = e.nn(L.jo).toString()),
            n.appendChild(o),
            !e.nn(L.Lh))
          ) {
            const e = new Date().valueOf();
            o.onclick = (o) => {
              new Date().valueOf() - e > InAppMessage.fh &&
                (t.Ae(s), o.stopPropagation());
            };
          }
          n.appendChild(s), s.focus(), t.Oh(n);
        } else if (a) {
          const e = document.querySelectorAll(".ab-slideup");
          let o = null;
          for (let t = e.length - 1; t >= 0; t--)
            if (e[t] !== s) {
              o = e[t];
              break;
            }
          if (t.slideFrom === InAppMessage.SlideFrom.TOP) {
            let e = 0;
            null != o && (e = o.offsetTop + o.offsetHeight),
              (s.style.top = Math.max(e, 0) + "px");
          } else {
            let e = 0;
            null != o &&
              (e =
                (window.innerHeight || document.documentElement.clientHeight) -
                o.offsetTop),
              (s.style.bottom = Math.max(e, 0) + "px");
          }
        } else if (i && !e.nn(L.Lh)) {
          const e = t;
          It(s) &&
            s.contentWindow &&
            s.contentWindow.addEventListener("keydown", function (t) {
              t.keyCode === lt.mh && e.closeMessage();
            });
        }
        logInAppMessageImpression(t),
          t.dismissType === InAppMessage.DismissType.AUTO_DISMISS &&
            setTimeout(() => {
              n.contains(s) && t.Ae(s);
            }, t.duration),
          "function" == typeof o && o();
      },
      (e) => {
        r.info(e);
      },
      e.nn(L.ho),
      e.nn(L.jo),
      e.nn(L.bo),
      s,
    );
  return (i || a) && (n.appendChild(l), t.Oh(n)), !0;
}
