import InAppMessage from "./in-app-message.js";
import T from "../../managers/subscription-manager.js";
export default class InAppMessageButton {
  constructor(s, t, i, r, h, e, n) {
    (this.text = s),
      (this.backgroundColor = t),
      (this.textColor = i),
      (this.borderColor = r),
      (this.clickAction = h),
      (this.uri = e),
      (this.id = n),
      (this.text = s || ""),
      (this.backgroundColor = t || InAppMessage.th.sh),
      (this.textColor = i || InAppMessage.th.ih),
      (this.borderColor = r || this.backgroundColor),
      (this.clickAction = h || InAppMessage.ClickAction.NONE),
      (this.uri = e),
      null == n && (n = InAppMessageButton.Ji),
      (this.id = n),
      (this.vo = !1),
      (this.Et = new T());
  }
  subscribeToClickedEvent(s) {
    return this.Et.It(s);
  }
  removeSubscription(s) {
    this.Et.removeSubscription(s);
  }
  removeAllSubscriptions() {
    this.Et.removeAllSubscriptions();
  }
  p() {
    return !this.vo && ((this.vo = !0), this.Et.Dt(), !0);
  }
  static fromJson(s) {
    return new InAppMessageButton(
      s.text,
      s.bg_color,
      s.text_color,
      s.border_color,
      s.click_action,
      s.uri,
      s.id,
    );
  }
}
InAppMessageButton.Ji = -1;
