import { buttonsFromSerializedInAppMessage as pe } from "../in-app-message-factory.js";
import InAppMessage from "./in-app-message.js";
export default class FullScreenMessage extends InAppMessage {
  constructor(
    e,
    r,
    s,
    t,
    i,
    a,
    o,
    p,
    m,
    n,
    u,
    c,
    f,
    d,
    l,
    g,
    j,
    x,
    z,
    h,
    v,
    w,
    y,
    S,
    b,
    k,
    q,
    A,
    B,
  ) {
    (p = p || InAppMessage.DismissType.MANUAL),
      (k = k || InAppMessage.Orientation.PORTRAIT),
      super(
        e,
        r,
        void 0,
        s,
        t,
        i,
        a,
        o,
        p,
        m,
        n,
        u,
        c,
        f,
        d,
        l,
        g,
        j,
        x,
        z,
        h,
        v,
        w,
        y,
        S,
        (b = b || InAppMessage.CropType.CENTER_CROP),
        k,
        q,
        A,
        B,
      ),
      (this.De = InAppMessage.TextAlignment.CENTER);
  }
  Y() {
    return super.Y(FullScreenMessage.it);
  }
  static Xr(e) {
    return new FullScreenMessage(
      e[InAppMessage.tt.Vr],
      e[InAppMessage.tt.Wr],
      e[InAppMessage.tt.bt],
      e[InAppMessage.tt.Yr],
      e[InAppMessage.tt.Zr],
      e[InAppMessage.tt.URI],
      e[InAppMessage.tt.ea],
      e[InAppMessage.tt.ra],
      e[InAppMessage.tt.sa],
      e[InAppMessage.tt.ta],
      e[InAppMessage.tt.ot],
      e[InAppMessage.tt.ia],
      e[InAppMessage.tt.aa],
      e[InAppMessage.tt.oa],
      e[InAppMessage.tt.pa],
      e[InAppMessage.tt.ma],
      e[InAppMessage.tt.na],
      e[InAppMessage.tt.ua],
      e[InAppMessage.tt.ca],
      e[InAppMessage.tt.fa],
      e[InAppMessage.tt.da],
      e[InAppMessage.tt.la],
      e[InAppMessage.tt.ga],
      pe(e[InAppMessage.tt.ja]),
      e[InAppMessage.tt.xa],
      e[InAppMessage.tt.za],
      e[InAppMessage.tt.ha],
      e[InAppMessage.tt.CSS],
      e[InAppMessage.tt.va],
    );
  }
}
FullScreenMessage.it = InAppMessage.qe.Or;
