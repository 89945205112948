import e from "../managers/braze-instance.js";
import InAppMessage from "./models/in-app-message.js";
import se from "./in-app-message-manager-factory.js";
import { logInAppMessageImpression } from "./log-in-app-message-impression.js";
import { MUST_BE_IN_APP_MESSAGE_WARNING as sr } from "../common/constants.js";
import { logger as r, EventTypes as i } from "../../shared-lib/index.js";
import tt from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as et } from "../triggers/triggers-provider-factory.js";
export function logInAppMessageClick(o) {
  if (!e.X()) return !1;
  if (!(o instanceof InAppMessage)) return r.error(sr), !1;
  const s = se.m().q(o, i.Ei);
  if (s) {
    o.Ur() || logInAppMessageImpression(o);
    for (let r = 0; r < s.ge.length; r++)
      et.rr().fe(tt.Qr, [o.triggerId], s.ge[r]);
  }
  return s.L;
}
