import kt from "./auth-manager.js";
import y from "../common/base-provider.js";
import X from "../util/browser-detector.js";
import Ot from "./device-manager.js";
import DeviceProperties from "../Core/device-properties.js";
import {
  isArray as j,
  keys as to,
  validateValueIsFromEnum as J,
  values as Pt,
} from "../util/code-utils.js";
import Rt from "./network-manager.js";
import Lt from "../request-controller.js";
import Dt from "./server-config-manager.js";
import Mt from "./session-manager.js";
import Q, { STORAGE_KEYS as o } from "./storage-manager.js";
import Bt from "./storage-manager-factory.js";
import T from "./subscription-manager.js";
import { TriggersProviderFactory as et } from "../triggers/triggers-provider-factory.js";
import bt from "../User/user-manager.js";
import { User } from "../User/index.js";
import { parseQueryStringKeyValues as ut } from "../util/url-utils.js";
import { WindowUtils as eo } from "../util/window-utils.js";
import { BRAZE_MUST_BE_INITIALIZED_ERROR as _ } from "../common/constants.js";
import { SupportedOptions as Wt, logger as r } from "../../shared-lib/index.js";
import _t from "../models/identifier.js";
const L = {
  Eo: "allowCrawlerActivity",
  _o: "baseUrl",
  Io: "noCookies",
  Ao: "devicePropertyAllowlist",
  _a: "disablePushTokenMaintenance",
  So: "enableLogging",
  No: "enableSdkAuthentication",
  Ma: "manageServiceWorkerExternally",
  Oo: "minimumIntervalBetweenTriggerActionsInSeconds",
  Po: "sessionTimeoutInSeconds",
  Ro: "appVersion",
  Lo: "appVersionNumber",
  wa: "serviceWorkerLocation",
  ya: "safariWebsitePushId",
  zn: "localization",
  bo: "contentSecurityNonce",
  Do: "allowUserSuppliedJavascript",
  jo: "inAppMessageZIndex",
  ho: "openInAppMessagesInNewTab",
  tn: "openCardsInNewTab",
  en: "openNewsFeedCardsInNewTab",
  Lh: "requireExplicitInAppMessageDismissal",
  Mo: "doNotLoadFontAwesome",
  Uo: "deviceId",
  Bo: "sdkFlavor",
};
class Vt {
  constructor() {
    (this.rn = ""),
      (this.Wo = ""),
      (this.Vo = void 0),
      (this.zo = null),
      (this.on = null),
      (this.qt = null),
      (this.zi = null),
      (this.yt = null),
      (this.ri = null),
      (this.u = null),
      (this.wt = null),
      (this.Go = ""),
      (this.Ko = !1),
      (this.$o = !1),
      (this.qo = new T()),
      (this.Jo = new T()),
      (this.options = {}),
      (this.Yo = []),
      (this.Ho = []),
      (this._e = []),
      (this.Wo = "5.3.1");
  }
  Xo(t) {
    this.qo.It(t);
  }
  Sh(t) {
    this.Jo.It(t);
  }
  initialize(t, i) {
    if (this.so())
      return r.info("Braze has already been initialized with an API key."), !0;
    this.options = i || {};
    let s = this.nn(L.So);
    const e = ut(eo.Un());
    if (
      (e && "true" === e.brazeLogging && (s = !0),
      r.init(s),
      r.info(
        `Initialization Options: ${JSON.stringify(this.options, null, 2)}`,
      ),
      null == t || "" === t || "string" != typeof t)
    )
      return r.error("Braze requires a valid API key to be initialized."), !1;
    this.rn = t;
    let n = this.nn(L._o);
    if (null == n || "" === n || "string" != typeof n)
      return r.error("Braze requires a valid baseUrl to be initialized."), !1;
    !1 === /^https?:/.test(n) && (n = `https://${n}`);
    const h = n;
    if (
      ((n = document.createElement("a")),
      (n.href = h),
      "/" === n.pathname && (n = `${n}api/v3`),
      (this.Go = n.toString()),
      X.Zo && !this.nn(L.Eo))
    )
      return (
        r.info("Ignoring activity from crawler bot " + navigator.userAgent),
        (this.$o = !0),
        !1
      );
    const a = this.nn(L.Io) || !1;
    if (
      ((this.u = Bt.Qo(t, a)), a && this.u.Wh(t), new Q.ee(null, !0).br(o.se))
    )
      return (
        r.info("Ignoring all activity due to previous opt out"),
        (this.$o = !0),
        !1
      );
    for (const t of to(this.options))
      -1 === Pt(Wt).indexOf(t) &&
        r.warn(`Ignoring unknown initialization option '${t}'.`);
    const l = ["mparticle", "wordpress", "tealium"];
    if (null != this.nn(L.Bo)) {
      const t = this.nn(L.Bo);
      -1 !== l.indexOf(t)
        ? (this.Vo = t)
        : r.error("Invalid sdk flavor passed: " + t);
    }
    let u = this.nn(Wt.Ao);
    if (null != u)
      if (j(u)) {
        const t = [];
        for (let i = 0; i < u.length; i++)
          J(
            DeviceProperties,
            u[i],
            "devicePropertyAllowlist contained an invalid value.",
            "DeviceProperties",
          ) && t.push(u[i]);
        u = t;
      } else
        r.error(
          "devicePropertyAllowlist must be an array. Defaulting to all properties.",
        ),
          (u = null);
    const f = this.nn(L.Uo);
    if (f) {
      const t = new _t(f);
      this.u.uu(o.iu.Uo, t);
    }
    (this.on = new Ot(this.u, u)),
      (this.yt = new Dt(this.u)),
      (this.wt = new bt(this.yt, this.u)),
      (this.ri = new Mt(this.u, this.wt, this.yt, this.nn(L.Po)));
    const c = new T();
    return (
      (this.zo = new kt(this.u, this.nn(L.No), c)),
      this.$t(c),
      (this.qt = new Rt(
        this.on,
        this.u,
        this.zo,
        this.wt,
        this.ri,
        this.yt,
        this.rn,
        this.Go,
        this.Wo,
        this.Vo || "",
        this.nn(L.Ro),
        this.nn(L.Lo),
      )),
      (this.zi = new Lt(
        this.rn,
        this.Go,
        this.ri,
        this.on,
        this.wt,
        this.yt,
        this.u,
        (t) => {
          if (this.so()) for (const i of this.gr()) i.Ns(t);
        },
        this.zo,
        this.qt,
      )),
      this.zi.initialize(),
      a || this.u.Vh(),
      r.info(
        `Initialized for the Braze backend at "${this.nn(
          L._o,
        )}" with API key "${this.rn}".`,
      ),
      et.o(),
      this.yt.ji(() => {
        var t;
        this.Ko &&
          (null === (t = this.yt) || void 0 === t ? void 0 : t.ci()) &&
          import("../FeatureFlags/refresh-feature-flags.js").then((t) => {
            if (!this.Ko) return;
            (0, t.default)();
          });
      }),
      this.zi.mr(() => {
        var t;
        this.Ko &&
          (null === (t = this.yt) || void 0 === t ? void 0 : t.ci()) &&
          import("../FeatureFlags/refresh-feature-flags.js").then((t) => {
            if (!this.Ko) return;
            (0, t.default)(void 0, void 0, !0);
          });
      }),
      this.qo.Dt(this.options),
      (this.Ko = !0),
      !0
    );
  }
  destroy(t) {
    if ((r.destroy(), this.so())) {
      this.Jo.Dt(), this.Jo.removeAllSubscriptions();
      for (const t of this.Yo) t.destroy();
      this.Yo = [];
      for (const t of this.Ho) t.clearData(!1);
      (this.Ho = []),
        this.removeAllSubscriptions(),
        (this._e = []),
        null != this.zi && this.zi.destroy(),
        (this.zi = null),
        (this.zo = null),
        (this.on = null),
        (this.qt = null),
        (this.yt = null),
        (this.ri = null),
        (this.wt = null),
        (this.options = {}),
        (this.Vo = void 0),
        (this.Ko = !1),
        (this.$o = !1),
        t && (this.u = null);
    }
  }
  X() {
    return !this.Kh() && (!!this.so() || (console.warn(_), !1));
  }
  ba() {
    return this.rn;
  }
  Sr() {
    return this.zo;
  }
  Hs() {
    return this.Go;
  }
  te() {
    return this.on;
  }
  nr() {
    return this.qt;
  }
  nn(t) {
    return this.options[t];
  }
  gr() {
    return this.Ho;
  }
  cr() {
    return this.zi;
  }
  ir() {
    return this.yt;
  }
  zr() {
    return this.ri;
  }
  l() {
    return this.u;
  }
  pr() {
    if (this.wt && this.zi) return new User(this.wt, this.zi);
  }
  er() {
    return this.wt;
  }
  tr() {
    return !0 === this.nn(L.Do);
  }
  g(t) {
    let i = !1;
    for (const s of this.Yo) s === t && (i = !0);
    i || this.Yo.push(t);
  }
  ar(t) {
    let i = !1;
    for (const s of this.Ho) s.constructor === t.constructor && (i = !0);
    t instanceof y && !i && this.Ho.push(t);
  }
  $t(t) {
    t instanceof T && this._e.push(t);
  }
  removeAllSubscriptions() {
    if (this.X()) for (const t of this._e) t.removeAllSubscriptions();
  }
  removeSubscription(t) {
    if (this.X()) for (const i of this._e) i.removeSubscription(t);
  }
  oe(t) {
    this.$o = t;
  }
  so() {
    return this.Ko;
  }
  Kh() {
    return this.$o;
  }
  Ds() {
    return this.Wo;
  }
}
const e = new Vt();
export { e as default, Vt as BrazeSdkInstance, L as OPTIONS };
