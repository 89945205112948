import {
  addPassiveEventListener as oe,
  buildSvg as ae,
  detectSwipe as c,
  DIRECTIONS as m,
} from "../../util/dom-utils.js";
import { createCloseButton as d } from "../../util/component-utils.js";
import { isTransparent as ne, toRgba as ie } from "../../util/color-utils.js";
import FullScreenMessage from "../models/full-screen-message.js";
import HtmlMessage from "../models/html-message.js";
import InAppMessage from "../models/in-app-message.js";
import ModalMessage from "../models/modal-message.js";
import SlideUpMessage from "../models/slide-up-message.js";
import { logInAppMessageClick } from "../log-in-app-message-click.js";
import { _handleBrazeAction as u } from "../../Core/handle-braze-action.js";
import at from "./html-message-to-html.js";
import le from "./modal-utils.js";
import { logger as r, Guid as p } from "../../../shared-lib/index.js";
export default function ce(e, o, a, t, n, s, i, l = document.body) {
  if (((e.ke = document.activeElement), e instanceof HtmlMessage))
    return at(e, o, a, n, s, i);
  const b = (function (e, o, a, t, n, s, i = document.body) {
    let m = null;
    const l = document.createElement("div");
    (l.className = "ab-in-app-message ab-start-hidden ab-background"),
      s && (l.style.zIndex = (s + 1).toString()),
      e.ze() &&
        ((l.className += " ab-modal-interactions"),
        l.setAttribute("tabindex", "-1")),
      e.Ce() ||
        ((l.style.color = ie(e.textColor)),
        (l.style.backgroundColor = ie(e.backgroundColor)),
        ne(e.backgroundColor) && (l.className += " ab-no-shadow"));
    const c = () => {
        -1 !== l.className.indexOf("ab-start-hidden") &&
          ((l.className = l.className.replace("ab-start-hidden", "")),
          document.querySelectorAll(".ab-iam-img-loading").length > 0
            ? t(
                `Cannot show in-app message ${e.message} because another message is being shown.`,
                InAppMessage.Be.Le,
              )
            : a(l));
      },
      b = (o = !0) => {
        let a = document.querySelectorAll(".ab-iam-root");
        (a && 0 !== a.length) || (a = i.querySelectorAll(".ab-iam-root")),
          a &&
            a.length > 0 &&
            (a[0].classList.remove("ab-iam-img-loading"),
            m && (clearTimeout(m), (m = null)),
            o
              ? c()
              : r.error(
                  `Cannot show in-app message ${e.message} because the image failed to load.`,
                ));
      };
    e.imageStyle === InAppMessage.ImageStyle.GRAPHIC &&
      (l.className += " graphic"),
      e.orientation === InAppMessage.Orientation.LANDSCAPE &&
        (l.className += " landscape"),
      null != e.buttons &&
        0 === e.buttons.length &&
        (e.clickAction !== InAppMessage.ClickAction.NONE &&
          (l.className += " ab-clickable"),
        (l.onclick = (a) => (
          e.Ae(l, () => {
            logInAppMessageClick(e),
              e.clickAction === InAppMessage.ClickAction.URI
                ? u(
                    e.uri || "",
                    n || e.openTarget === InAppMessage.OpenTarget.BLANK,
                    a,
                  )
                : e.clickAction === InAppMessage.ClickAction.NEWS_FEED && o();
          }),
          a.stopPropagation(),
          !1
        )));
    const g = d(
      "Close Message",
      e.Ce() ? void 0 : ie(e.closeButtonColor),
      () => {
        e.Ae(l);
      },
    );
    l.appendChild(g), s && (g.style.zIndex = (s + 2).toString());
    const f = document.createElement("div");
    f.className = "ab-message-text";
    const h = (e.messageAlignment || e.De).toLowerCase();
    f.className += " " + h + "-aligned";
    let j = !1;
    const w = document.createElement("div");
    if (((w.className = "ab-image-area"), e.imageUrl)) {
      if (e.cropType === InAppMessage.CropType.CENTER_CROP) {
        const o = document.createElement("span");
        (o.className = "ab-center-cropped-img"),
          (o.style.backgroundImage = "url(" + e.imageUrl + ")"),
          o.setAttribute("role", "img"),
          o.setAttribute("aria-label", "Modal Image"),
          e.Ge(o),
          w.appendChild(o);
      } else {
        const o = document.createElement("img");
        if (
          (o.setAttribute("src", e.imageUrl),
          e.Ge(o),
          0 === document.querySelectorAll(".ab-iam-img-loading").length)
        ) {
          j = !0;
          const e = document.querySelectorAll(".ab-iam-root");
          e && e.length > 0 && e[0].classList.add("ab-iam-img-loading"),
            (m = window.setTimeout(() => {
              b(!1);
            }, 6e4)),
            (o.onload = () => {
              b();
            }),
            (o.onerror = () => {
              b(!1);
            });
        }
        w.appendChild(o);
      }
      l.appendChild(w), (f.className += " ab-with-image");
    } else if (e.icon) {
      w.className += " ab-icon-area";
      const o = document.createElement("span");
      (o.className = "ab-icon"),
        e.Ce() ||
          ((o.style.backgroundColor = ie(e.iconBackgroundColor)),
          (o.style.color = ie(e.iconColor)));
      const a = document.createElement("i");
      (a.className = "fa"),
        a.appendChild(document.createTextNode(e.icon)),
        a.setAttribute("aria-hidden", "true"),
        o.appendChild(a),
        w.appendChild(o),
        l.appendChild(w),
        (f.className += " ab-with-icon");
    }
    if ((oe(f, "touchstart"), e.header && e.header.length > 0)) {
      const o = document.createElement("h1");
      (o.className = "ab-message-header"), (e.He = p.W()), (o.id = e.He);
      const a = (
        e.headerAlignment || InAppMessage.TextAlignment.CENTER
      ).toLowerCase();
      (o.className += " " + a + "-aligned"),
        e.Ce() || (o.style.color = ie(e.headerTextColor)),
        o.appendChild(document.createTextNode(e.header)),
        f.appendChild(o);
    }
    return f.appendChild(e.Je()), l.appendChild(f), j || c(), (e.Me = l), l;
  })(e, o, a, t, n, s, l);
  if (e instanceof FullScreenMessage || e instanceof ModalMessage) {
    const a = e instanceof FullScreenMessage ? "ab-fullscreen" : "ab-modal";
    (b.className += ` ${a} ab-centered`),
      le.Ke(e, o, b, n),
      le.Oe(b),
      le.Qe(e.He, b);
  } else if (e instanceof SlideUpMessage) {
    b.className += " ab-slideup";
    const o = b.getElementsByClassName("ab-close-button")[0];
    if (null != o) {
      const a = ae(
        "0 0 11.38 19.44",
        "M11.38 9.72l-9.33 9.72L0 17.3l7.27-7.58L0 2.14 2.05 0l9.33 9.72z",
        e.Ce() ? void 0 : ie(e.closeButtonColor),
      );
      a.setAttribute("class", "ab-chevron"), o.appendChild(a);
    }
    let a, t;
    c(b, m.U, (e) => {
      (b.className += " ab-swiped-left"),
        null != o && null != o.onclick && o.onclick(e);
    }),
      c(b, m.V, (e) => {
        (b.className += " ab-swiped-right"),
          null != o && null != o.onclick && o.onclick(e);
      }),
      e.slideFrom === InAppMessage.SlideFrom.TOP
        ? ((a = m.Re), (t = " ab-swiped-up"))
        : ((a = m.Se), (t = " ab-swiped-down")),
      c(b, a, (e) => {
        (b.className += t), null != o && null != o.onclick && o.onclick(e);
      });
  }
  return b;
}
