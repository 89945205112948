export const ORIENTATION = { PORTRAIT: 0, LANDSCAPE: 1 };
export function _isPhone() {
  return screen.width <= 600;
}
export function _getOrientation() {
  if ("orientation" in window)
    return 90 === Math.abs(window.orientation) || 270 === window.orientation
      ? ORIENTATION.LANDSCAPE
      : ORIENTATION.PORTRAIT;
  const n = window;
  if ("screen" in n) {
    let e =
      n.screen.orientation || screen.mozOrientation || screen.msOrientation;
    return (
      null != e && "object" == typeof e && (e = e.type),
      "landscape-primary" === e || "landscape-secondary" === e
        ? ORIENTATION.LANDSCAPE
        : ORIENTATION.PORTRAIT
    );
  }
  return ORIENTATION.PORTRAIT;
}
export function _openUri(n, e, t) {
  n && (e || (null != t && t.metaKey) ? window.open(n) : (window.location = n));
}
export function _getCurrentUrl() {
  return window.location.href;
}
export const WindowUtils = {
  openUri: _openUri,
  fo: _isPhone,
  co: _getOrientation,
  Un: _getCurrentUrl,
};
