import e, { OPTIONS as L } from "../managers/braze-instance.js";
import ea from "./push-manager.js";
const na = {
  t: !1,
  i: null,
  m: () => (
    na.o(),
    na.i ||
      (na.i = new ea(
        e.pr(),
        e.ba(),
        e.te(),
        e.Hs(),
        e.nn(L.wa),
        e.nn(L.ya),
        e.ir(),
        e.nn(L.Ma),
        e.nn(L._a),
        e.l(),
      )),
    na.i
  ),
  o: () => {
    na.t || (e.g(na), (na.t = !0));
  },
  destroy: () => {
    (na.i = null), (na.t = !1);
  },
};
export default na;
