import { isArray as j } from "./code-utils.js";
export default class ge {
  constructor() {}
  ef() {}
  rf() {}
  Ta(t) {}
  static sf(t, e) {
    if (t && e)
      if (((t = t.toLowerCase()), j(e.ff))) {
        for (let r = 0; r < e.ff.length; r++)
          if (-1 !== t.indexOf(e.ff[r].toLowerCase())) return e.identity;
      } else if (-1 !== t.indexOf(e.ff.toLowerCase())) return e.identity;
  }
}
