import {
  isArray as j,
  isDate as yt,
  isObject as Nt,
  validateValueIsFromEnum as J,
} from "../util/code-utils.js";
import { logger as r, EventTypes as i } from "../../shared-lib/index.js";
import {
  isValidEmail as Ct,
  validateCustomAttributeArrayType as St,
  validateCustomAttributeKey as At,
  validateCustomProperties as ot,
  validateCustomString as rt,
  validatePropertyType as Ut,
  validateStandardString as P,
} from "../util/validation-utils.js";
import { SET_CUSTOM_USER_ATTRIBUTE_STRING as Ft } from "../common/constants.js";
import { toValidBackendTimeString as $t } from "../util/date-utils.js";
export default class User {
  constructor(t, e) {
    (this.wt = t), (this.zi = e), (this.wt = t), (this.zi = e);
  }
  getUserId(t) {
    const e = this.wt.getUserId();
    if ("function" != typeof t) return e;
    r.warn(
      "The callback for getUserId is deprecated. You can access its return value directly instead (e.g. `const id = braze.getUser().getUserId()`)",
    ),
      t(e);
  }
  addAlias(t, e) {
    return !P(t, "add alias", "the alias", !1) || t.length <= 0
      ? (r.error("addAlias requires a non-empty alias"), !1)
      : !P(e, "add alias", "the label", !1) || e.length <= 0
      ? (r.error("addAlias requires a non-empty label"), !1)
      : this.zi.Cn(t, e).L;
  }
  setFirstName(t) {
    return (
      !!P(t, "set first name", "the firstName", !0) &&
      this.wt.nu("first_name", t)
    );
  }
  setLastName(t) {
    return (
      !!P(t, "set last name", "the lastName", !0) && this.wt.nu("last_name", t)
    );
  }
  setEmail(t) {
    return null === t || Ct(t)
      ? this.wt.nu("email", t)
      : (r.error(
          `Cannot set email address - "${t}" did not pass RFC-5322 validation.`,
        ),
        !1);
  }
  setGender(t) {
    return (
      "string" == typeof t && (t = t.toLowerCase()),
      !(
        null !== t &&
        !J(
          User.Genders,
          t,
          `Gender "${t}" is not a valid gender.`,
          "User.Genders",
        )
      ) && this.wt.nu("gender", t)
    );
  }
  setDateOfBirth(t, e, s) {
    return null === t && null === e && null === s
      ? this.wt.nu("dob", null)
      : ((t = null != t ? parseInt(t.toString()) : null),
        (e = null != e ? parseInt(e.toString()) : null),
        (s = null != s ? parseInt(s.toString()) : null),
        null == t ||
        null == e ||
        null == s ||
        isNaN(t) ||
        isNaN(e) ||
        isNaN(s) ||
        e > 12 ||
        e < 1 ||
        s > 31 ||
        s < 1
          ? (r.error(
              "Cannot set date of birth - parameters should comprise a valid date e.g. setDateOfBirth(1776, 7, 4);",
            ),
            !1)
          : this.wt.nu("dob", `${t}-${e}-${s}`));
  }
  setCountry(t) {
    return !!P(t, "set country", "the country", !0) && this.wt.nu("country", t);
  }
  setHomeCity(t) {
    return (
      !!P(t, "set home city", "the homeCity", !0) && this.wt.nu("home_city", t)
    );
  }
  setLanguage(t) {
    return (
      !!P(t, "set language", "the language", !0) && this.wt.nu("language", t)
    );
  }
  setEmailNotificationSubscriptionType(t) {
    return (
      !!J(
        User.NotificationSubscriptionTypes,
        t,
        `Email notification setting "${t}" is not a valid subscription type.`,
        "User.NotificationSubscriptionTypes",
      ) && this.wt.nu("email_subscribe", t)
    );
  }
  setPushNotificationSubscriptionType(t) {
    return (
      !!J(
        User.NotificationSubscriptionTypes,
        t,
        `Push notification setting "${t}" is not a valid subscription type.`,
        "User.NotificationSubscriptionTypes",
      ) && this.wt.nu("push_subscribe", t)
    );
  }
  setPhoneNumber(t) {
    return (
      !!P(t, "set phone number", "the phoneNumber", !0) &&
      (null === t || t.match(User.En)
        ? this.wt.nu("phone", t)
        : (r.error(`Cannot set phone number - "${t}" did not pass validation.`),
          !1))
    );
  }
  setLastKnownLocation(t, e, s, n, i) {
    return null == t || null == e
      ? (r.error(
          "Cannot set last-known location - latitude and longitude are required.",
        ),
        !1)
      : ((t = parseFloat(t.toString())),
        (e = parseFloat(e.toString())),
        null != s && (s = parseFloat(s.toString())),
        null != n && (n = parseFloat(n.toString())),
        null != i && (i = parseFloat(i.toString())),
        isNaN(t) ||
        isNaN(e) ||
        (null != s && isNaN(s)) ||
        (null != n && isNaN(n)) ||
        (null != i && isNaN(i))
          ? (r.error(
              "Cannot set last-known location - all supplied parameters must be numeric.",
            ),
            !1)
          : t > 90 || t < -90 || e > 180 || e < -180
          ? (r.error(
              "Cannot set last-known location - latitude and longitude are bounded by ±90 and ±180 respectively.",
            ),
            !1)
          : (null != s && s < 0) || (null != i && i < 0)
          ? (r.error(
              "Cannot set last-known location - accuracy and altitudeAccuracy may not be negative.",
            ),
            !1)
          : this.zi.setLastKnownLocation(this.wt.getUserId(), t, e, n, s, i).L);
  }
  setCustomUserAttribute(t, e, r) {
    if (!At(t)) return !1;
    const s = (e) => {
      const [r] = ot(
        e,
        Ft,
        "attribute value",
        `set custom user attribute "${t}"`,
        "custom user attribute",
      );
      return r;
    };
    if (j(e)) {
      const [r, n] = St(t, e);
      if (!r && !n && 0 !== e.length) return !1;
      if (r || 0 === e.length) return this.zi.Fn(i.$n, t, e).L;
      for (const t of e) if (!s(t)) return !1;
    } else if (Nt(e)) {
      if (!s(e)) return !1;
      if (r) return this.zi.Fn(i.Bn, t, e).L;
    } else {
      if (!(void 0 !== e && Ut(e))) return !1;
      if (
        (yt(e) && (e = $t(e)),
        "string" == typeof e &&
          !rt(
            e,
            `set custom user attribute "${t}"`,
            "the element in the given array",
          ))
      )
        return !1;
    }
    return this.wt.setCustomUserAttribute(t, e);
  }
  addToCustomAttributeArray(t, e) {
    return (
      !!rt(t, "add to custom user attribute array", "the given key") &&
      !(
        null != e &&
        !rt(e, "add to custom user attribute array", "the given value")
      ) &&
      this.zi.Fn(i.Ln, t, e).L
    );
  }
  removeFromCustomAttributeArray(t, e) {
    return (
      !!rt(t, "remove from custom user attribute array", "the given key") &&
      !(
        null != e &&
        !rt(e, "remove from custom user attribute array", "the given value")
      ) &&
      this.zi.Fn(i.Rn, t, e).L
    );
  }
  incrementCustomUserAttribute(t, e) {
    if (!rt(t, "increment custom user attribute", "the given key")) return !1;
    null == e && (e = 1);
    const s = parseInt(e.toString());
    return isNaN(s) || s !== parseFloat(e.toString())
      ? (r.error(
          `Cannot increment custom user attribute because the given incrementValue "${e}" is not an integer.`,
        ),
        !1)
      : this.zi.Fn(i.On, t, s).L;
  }
  setCustomLocationAttribute(t, e, s) {
    return (
      !!rt(t, "set custom location attribute", "the given key") &&
      ((null !== e || null !== s) &&
      ((e = null != e ? parseFloat(e.toString()) : null),
      (s = null != s ? parseFloat(s.toString()) : null),
      (null == e && null != s) ||
        (null != e && null == s) ||
        (null != e && (isNaN(e) || e > 90 || e < -90)) ||
        (null != s && (isNaN(s) || s > 180 || s < -180)))
        ? (r.error(
            "Received invalid values for latitude and/or longitude. Latitude and longitude are bounded by ±90 and ±180 respectively, or must both be null for removal.",
          ),
          !1)
        : this.zi.Gn(t, e, s).L)
    );
  }
  addToSubscriptionGroup(t) {
    return !P(
      t,
      "add user to subscription group",
      "subscription group ID",
      !1,
    ) || t.length <= 0
      ? (r.error(
          "addToSubscriptionGroup requires a non-empty subscription group ID",
        ),
        !1)
      : this.zi.Hn(t, User.Kn.SUBSCRIBED).L;
  }
  removeFromSubscriptionGroup(t) {
    return !P(
      t,
      "remove user from subscription group",
      "subscription group ID",
      !1,
    ) || t.length <= 0
      ? (r.error(
          "removeFromSubscriptionGroup requires a non-empty subscription group ID",
        ),
        !1)
      : this.zi.Hn(t, User.Kn.UNSUBSCRIBED).L;
  }
  kn(t, e, r, s, n) {
    this.wt.kn(t, e, r, s, n), this.zi.Mn();
  }
  Dn(t) {
    this.wt.Dn(t);
  }
}
(User.Genders = {
  MALE: "m",
  FEMALE: "f",
  OTHER: "o",
  UNKNOWN: "u",
  NOT_APPLICABLE: "n",
  PREFER_NOT_TO_SAY: "p",
}),
  (User.NotificationSubscriptionTypes = {
    OPTED_IN: "opted_in",
    SUBSCRIBED: "subscribed",
    UNSUBSCRIBED: "unsubscribed",
  }),
  (User.En = /^[0-9 .\\(\\)\\+\\-]+$/),
  (User.Kn = { SUBSCRIBED: "subscribed", UNSUBSCRIBED: "unsubscribed" }),
  (User.Yn = "user_id"),
  (User.lu = "custom"),
  (User.lr = 997);
